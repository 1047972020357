<template>
	<div>
		<!-- 公共头部 -->
		<Header :title="title"></Header>
		<div class="section">
			<wd-search v-model="search" @search="getNewsList" hide-cancel placeholder-left></wd-search>

		</div>
	
		<!-- <div class="section1">
			<router-link :to="'/help?id='+item.id" class="item " v-for="(item,index) in cateList" :class="[ $route.query.id == item.id ? 'active':'' ]" :key="index">{{item.title}}</router-link>
		</div> -->
		<div class="section2">
			<ul class="list">
				<router-link :to="'/helpInfo?id='+item.cate_id+'&aid='+item.id" class="item" v-for="(item,index) in newsList" :key="index">
					<div class="title">{{item.title}}</div>
					<div class="time">{{item.addtime}}</div>
				</router-link>
			</ul>
			<wd-pagination v-model="page" :total="total"></wd-pagination>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '',
				cateList: [],
				page: 1,
				limit: 10,
				total: 0,
				newsList: [],
				search:''
			}
		},
		activated(){
			
				this.title = this.$route.query.title;
					this.getNewsList();
		},
		watch: {
			$route(to,form) {
				if((to.path == form.path) && (this.$route.path == '/help')){
					if(this.page == 1){
						this.getNewsList();
					} else {
						this.page = 1;
					}
				}
			},
			page() {
				if(this.$route.path == '/help'){
					this.getNewsList();
				}
			}
		},
		created() {
			this.getCateList();
		
		
		},
		methods: {
			async getCateList() {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/article/helpCate');
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('帮助公用：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.cateList = res.data;
				} else {
					this.cateList = [];
				}
			},
			async getNewsList() {
				var cateId = this.$route.query.id || 0;
				var page = this.page;
				var limit = this.limit;
				var keywords = this.search
				var data = {
					cateId, page, limit,keywords
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/article/helpList', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('帮助列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var total = res.data.count;
					this.total = total;
					var newsList = res.data.lists;
					for(var i = 0; i < newsList.length; i++) {
						newsList[i]['addtime'] = this.utils.js_date_time(newsList[i]['addtime'],1);
					}
					this.newsList = newsList;
				} else {
					this.newsList = [];
				}
			},
		
			
		},
	};
</script>

<style scoped>

.section1{
		white-space: nowrap;
		overflow-x: scroll;
		background: #F3F3F3;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section1 .item{
		font-size: 0.28rem;
		color: #888888;
		display: inline-block;
		vertical-align: top;
		height: 0.8rem;
		line-height: 0.8rem;
		padding: 0 0.3rem;
	}
	.section1 .item.active{
		position: relative;
		font-size: 0.28rem;
		color: #FFFFFF;
		background: #86cb08;
	}
	/* .section1 .item.active::after{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.48rem;
		height: 0.04rem;
		background: #ff6000;
		bottom: 0.08rem;
		border-radius: 0.02rem;
		left: 50%;
		transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		-o-transform: translate(-50%,0);
		-moz-transform: translate(-50%,0);
		-webkit-transform: translate(-50%,0);
	} */
	.section2{
		padding: 0.3rem 0 0.8rem;
	}
	.section2 .list{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		margin-bottom: 0.8rem;
	}
	.section2 .list .item{
		height: 1rem;
		border-top: 0.02rem solid #e7e7e7;
		padding: 0.3rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.section2 .list .item:first-child{
		border-top: none;
	}
	.section2 .list .item .title{
		font-size: 28rpx;
		color: #333333;
		max-width: 4.9rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.section2 .list .item .time{
		font-size: 24rpx;
		color: #969897;
		padding-right: 0.22rem;
		background: url(../../assets/images/icon16.png) no-repeat right center;
		background-size: 0.09rem 0.17rem;
	}
</style>